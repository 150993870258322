import { template as template_a0adffe5f11f41b7be6e48b13245d46b } from "@ember/template-compiler";
const WelcomeHeader = template_a0adffe5f11f41b7be6e48b13245d46b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
