import { template as template_2dc86205da43410aa7207dd95eacd2fe } from "@ember/template-compiler";
const FKText = template_2dc86205da43410aa7207dd95eacd2fe(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
