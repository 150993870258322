import { template as template_71aa812f100f4704aa76f8c129ed2635 } from "@ember/template-compiler";
const FKLabel = template_71aa812f100f4704aa76f8c129ed2635(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
