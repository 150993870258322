import { template as template_223ec04d014f46efa537c5a849ee16c7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_223ec04d014f46efa537c5a849ee16c7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
