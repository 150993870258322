import { template as template_540e807a782a4a5b9f6d93b86fba8bc5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_540e807a782a4a5b9f6d93b86fba8bc5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
