import { template as template_175c90fd6e9c46d4ab4fc66973134090 } from "@ember/template-compiler";
const EmptyState = template_175c90fd6e9c46d4ab4fc66973134090(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
